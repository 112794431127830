import React from 'react';
import '../graphql-fragments/localeFragments.ts';

import IndexPage from '../components/IndexPage';

const CitizenshipZaIndexPage = (): React.ReactElement => {
  return (
    <IndexPage
      country="za"
      pageTheme="citizenship"
      googleTagManagerTrackingId="GTM-TJNS6N7"
    ></IndexPage>
  );
};

export default CitizenshipZaIndexPage;
